<template>
    <div class="index-container">
        <div class="course-content">
            <div class="select-wrapper">
                <div class="select-left">轮播管理</div>
                <div class="select-btn">
                    <el-button type="primary" @click="addCarousel">新增轮播</el-button>
                </div>
            </div>
            <el-table :data="carouselList" border style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" :cell-style="{fontSize: '12px',color: '#333'}">
                <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                <el-table-column label="轮播链接">
                    <template slot-scope="scope">
                        {{webUrl + "/#/" + scope.row.banner_link}}
                    </template>
                </el-table-column>
                <el-table-column label="轮播图" align="center">
                    <template slot-scope="scope">
                        <span class="banner-img">
                            <img :src="scope.row.banner_img" alt="">
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="banner_sort" label="轮播图排序" width="120" align="center"></el-table-column>
                <el-table-column prop="create_time" label="发布时间" width="200" align="center"></el-table-column>
                <el-table-column label="操作" width="300" align="center">
                    <template slot-scope="scope">
                        <el-button size="small" @click="seeCarousel(scope.row)">查看</el-button>
                        <el-button size="small" type="primary" @click="editCarousel(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="delCarousel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--添加/编辑弹窗-->
            <el-dialog :title="carouselTitleType" :visible.sync="dialogAddCarousel" width="650px" center class="carousel-dialog" @close="resetForm()" :close-on-click-modal="false">
                <el-form :model="addCarouselForm" ref="addCarouselForm" :rules="rules" label-position="center" class="info-form">
                    <el-form-item label="轮播图上传" :label-width="formLabelWidth" prop="banner_img">
                        <el-upload class="carousel-uploader"
                                   :action="uploadBanner"
                                   :headers="headersParams"
                                   name="bannerImg"
                                   :show-file-list="false"
                                   :on-success="handleAvatarSuccess"
                                   :before-upload="beforeAvatarUpload">
                            <img v-if="bannerSrc" :src="bannerSrc" class="img">
                            <div v-if="bannerSrc === null">
                                <i class="el-icon-circle-plus"></i>
                                <div class="el-upload__text">点击上传图片</div>
                            </div>
                        </el-upload>
                        <span class="prompt-msg">轮播图尺寸：<span>1920*640</span></span>
                    </el-form-item>
                    <el-form-item label="轮播图链接" :label-width="formLabelWidth" prop="banner_link">
                        <el-input v-model="addCarouselForm.banner_link" autocomplete="off" placeholder="请输入轮播链接"></el-input>
                        <span class="prompt-msg">轮播图链接为"/#/"的内容，例：http://www.jdekt.com/#/<span>qualityCourse/contactUs</span></span>
                    </el-form-item>
                    <el-form-item label="轮播图排序" :label-width="formLabelWidth" prop="banner_sort">
                        <el-input v-model.number="addCarouselForm.banner_sort" autocomplete="off" placeholder="排序区间：1-255"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="dialogAddCarousel = false">取 消</el-button>
                    <el-button size="medium" type="primary" @click="addToForm('addCarouselForm')">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CarouselManage",
        data() {
            return {
                //轮播列表
                carouselList: [],
                //轮播图表单数据
                addCarouselForm: {
                    id: '',
                    banner_img: '',
                    banner_link: '',
                    banner_sort: ''
                },
                rules: {
                    banner_img: [
                        { required: true, message: '请选择轮播图片', trigger: 'change' }
                    ],
                    banner_link: [
                        { required: true, message: '请输入轮播图链接', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                    ],
                    banner_sort: [
                        { required: true, message: '请输入轮播图排序，1-255之间的数值', trigger: 'blur' },
                        { type: 'number', message: '请输入轮播图排序，1-255之间的数值', trigger: 'blur' },
                        { pattern: /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])$/, message: '范围在1-255', trigger: 'blur'},
                    ],
                },
                //弹窗标题
                carouselTitleType: '',
                //是否显示弹窗
                dialogAddCarousel: false,
                formLabelWidth: '100px',
                //编辑/保存
                distinguishBtn: '',
                uploadBanner: this.$api.bannerUpload,
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
                bannerSrc: null,
                webUrl: window.location.host,
            }
        },
        mounted() {
            this.getCarouselList()
        },
        methods: {
            getCarouselList() {
                this.$http.axiosGet(this.$api.bannerList, (res) => {
                    if (res.code === 200) {
                        this.carouselList = res.data
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //新增
            addCarousel() {
                if (this.carouselList.length > 7 || this.carouselList.length === 7) {
                    this.$message.error('轮播图最多可传7张，请先删除或者直接编辑');
                    return
                }
                this.dialogAddCarousel = true;
                this.carouselTitleType = '新增轮播';
                this.distinguishBtn = 'add';
            },
            //查看
            seeCarousel(row) {
                let routeUrl = this.$router.resolve({
                    path: '/'
                })
                window.open(routeUrl .href, '_blank')
            },
            //编辑
            editCarousel(row) {
                this.addCarouselForm = row;
                this.bannerSrc = row.banner_img;
                this.addCarouselForm.banner_img = row.src;
                this.dialogAddCarousel = true;
                this.carouselTitleType = '编辑轮播';
                this.distinguishBtn = 'edit';
            },
            //上传
            handleAvatarSuccess(res, file) {
                if (res.code === 200) {
                    this.bannerSrc = res.imgSrc;
                    this.addCarouselForm.banner_img = res.src
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            //保存轮播
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('img', this.addCarouselForm.banner_img)
                        formData.append('link', this.addCarouselForm.banner_link)
                        formData.append('sort', this.addCarouselForm.banner_sort)
                        if (this.addCarouselForm.id) {
                            formData.append('id', this.addCarouselForm.id)
                        }
                        this.$http.axiosPost(this.$api.bannerAdd, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.dialogAddCarousel = false;
                                this.getCarouselList();
                                this.resetForm();
                            } else {
                                this.$message.error(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //删除
            delCarousel(row) {
                this.$confirm('确定删除该新闻吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosPost(this.$api.bannerDel, {id: row.id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getCarouselList();
                        } else {
                            this.$message.error(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //重置表单
            resetForm() {
                this.addCarouselForm = {
                    id: '',
                    banner_img: '',
                    banner_link: '',
                    banner_sort: ''
                }
                this.bannerSrc = null;
            },
        }
    }
</script>

<style scoped lang="scss">
    .course-content {
        padding: 20px;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        .select-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .carousel-dialog {
        width: 100%;
        height: 100%;
        .el-dialog {
            width: 560px;
            height: 360px;
            .carousel-uploader {
                width: 260px;
                height: 100px;
                background-color: #F4F5F5;
                ::v-deep .el-upload {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    .el-icon-circle-plus {
                        color: #FF0013;
                        font-size: 26px;
                    }
                }
            }
        }
    }
    .banner-img {
        display: block;
        height: 60px;
        img {
            display: inline-block;
            max-height: 100%;
        }
    }
    .prompt-msg {
        font-size: 13px;
        color: #666;
        line-height: 18px;
        span {
            color: #FF0013;
        }
    }
</style>